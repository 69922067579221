import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader/root';

import Admin from './routes/admin';
import Auth from './routes/auth';
import ProtectedRoute from './components/utilities/protectedRoute';
import { isAdmin } from './services/authenticationService';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import config from './config/config';
import { store } from './redux/store';

import './fontello/css/fontello.css';
import 'styles/globals.css';

const { theme } = config;

const ProviderConfig = () => {
  const { isLoggedIn } = useSelector(state => state.auth);

  const NotFound = () => {
    return <Redirect to={isLoggedIn ? '/admin/auto-ecoles' : '/'} />;
  };

  return (
    <ConfigProvider>
      <ThemeProvider theme={{ ...theme }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn || !isAdmin() ? (
            <Route path="/" component={Auth} />
          ) : (
            <ProtectedRoute path="/admin" component={Admin} />
          )}
          <Route exact path="*" component={NotFound} />
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
