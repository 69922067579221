import axios from 'axios';
import { getRefreshToken, getToken, refreshTokenFunction } from './authenticationService';

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Get token
    const token = getToken();
    // Do something before request is sent
    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Add a response interceptor
axios.interceptors.response.use(
  response => response,
  async error => {
    console.log('error', error);
    if (error.response.status === 401) {
      try {
        await refreshTokenFunction(getRefreshToken());
        // Retry the original request with the new access token
        return axios(error.config);
      } catch (refreshError) {
        //  Redirec to login page
        window.location.href = '/';
      }
    }
    // Reject promise if usual error
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

const axiosService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
export default axiosService;
