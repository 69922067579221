import { createSlice } from '@reduxjs/toolkit';
import { columns } from './dataTable';
import {
  createSchool,
  getSchoolList,
  deleteSchool,
  editSchool,
  getOneSchool,
  getFinancialList,
  getVehiculesList,
  getServiceList,
  deleteServiceDate,
  saveServiceDate,
  editServiceDate,
  filterSchool,
  getSchoolsRequests,
  getSchoolOptionsList,
} from './schoolAsyncThunk';

const dataStatusObject = {
  data: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const initialState = {
  columns,
  details: {
    isOpen: false,
    data: null,
  },
  createOneSchool: {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: null,
  },
  filter: {
    nameCommercialOrEmailOrName: null,
    isVerifiedByAdmin: null,
    typePermit: null,
    isDeleted: null,
  },
  requests: null,

  schoolList: dataStatusObject,

  deleteOneSchool: dataStatusObject,

  editOneSchool: dataStatusObject,

  getOneSchool: dataStatusObject,

  getFinancial: dataStatusObject,

  getVehicules: dataStatusObject,

  getSchoolOptions: dataStatusObject,

  getServices: dataStatusObject,

  deleteDate: dataStatusObject,

  saveDate: dataStatusObject,

  editDate: dataStatusObject,

  searchSchool: null,
};

export const drivingSchoolSlice = createSlice({
  name: 'driving-school-list',
  initialState,
  reducers: {
    updateAddressOnEdit: (state, action) => {
      state.schoolDetails.data.adresse_drinving_school = action.payload;
    },
    filterDrivingSchool: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    emptyStates: (state, action) => {
      state.editOneSchool = dataStatusObject;
      state.getSchoolOptions = dataStatusObject;
      state.getOneSchool = dataStatusObject;
      state.getFinancial = dataStatusObject;
    },
    setServices: (state, action) => {
      const { title, newDate } = action.payload;
      const serviceIndex = state.getServices.data.findIndex(service => service.title === title);

      const updatedService = {
        ...state.getServices.data[serviceIndex],
        dateService: [...state.getServices.data[serviceIndex].dateService, newDate],
      };

      state.getServices.data[serviceIndex] = updatedService;
    },
    updateServices: (state, action) => {
      const { title } = action.payload;
      const serviceIndex = state.getServices.data.findIndex(service => service.title === title);

      const updatedDateService = state.getServices.data[serviceIndex].dateService.slice(0, -1);

      const updatedService = {
        ...state.getServices.data[serviceIndex],
        dateService: [...updatedDateService],
      };

      state.getServices.data[serviceIndex] = updatedService;
    },
  },
  extraReducers: builder => {
    //createSchool
    builder
      .addCase(createSchool.pending, state => {
        state.createOneSchool.isLoading = true;
      })
      .addCase(createSchool.fulfilled, (state, action) => {
        state.createOneSchool.isLoading = false;
        state.createOneSchool.isSuccess = true;
        state.createOneSchool.data.push(action.payload);
      })
      .addCase(createSchool.rejected, (state, action) => {
        state.createOneSchool.isLoading = false;
        state.createOneSchool.isError = action.payload;
      })

      //getSchoolList
      .addCase(getSchoolList.pending, state => {
        state.schoolList.isLoading = true;
      })
      .addCase(getSchoolList.fulfilled, (state, action) => {
        state.schoolList.isLoading = false;
        state.schoolList.data = action.payload;
      })
      .addCase(getSchoolList.rejected, (state, action) => {
        state.schoolList.isLoading = false;
        state.schoolList.isError = action.payload;
      })

      //deleteSchool
      .addCase(deleteSchool.pending, state => {
        state.deleteOneSchool.isLoading = true;
      })
      .addCase(deleteSchool.fulfilled, (state, action) => {
        state.deleteOneSchool.isLoading = false;
        state.deleteOneSchool.isSuccess = true;
        state.deleteOneSchool.data = action.payload;
      })
      .addCase(deleteSchool.rejected, (state, action) => {
        state.deleteOneSchool.isLoading = false;
        state.deleteOneSchool.isError = action.payload;
      })

      //getOneSchool
      .addCase(getOneSchool.pending, state => {
        state.getOneSchool.isLoading = true;
      })
      .addCase(getOneSchool.fulfilled, (state, action) => {
        state.getOneSchool.isLoading = false;
        state.getOneSchool.isSuccess = true;
        state.getOneSchool.data = action.payload;
      })
      .addCase(getOneSchool.rejected, (state, action) => {
        state.getOneSchool.isLoading = false;
        state.getOneSchool.isError = action.payload;
      })

      //editSchool
      .addCase(editSchool.pending, state => {
        state.editOneSchool.isLoading = true;
      })
      .addCase(editSchool.fulfilled, (state, action) => {
        state.editOneSchool.isLoading = false;
        state.editOneSchool.data = action.payload;
        state.editOneSchool.isError = false;
        state.editOneSchool.isSuccess = true;
      })
      .addCase(editSchool.rejected, (state, action) => {
        state.editOneSchool.isLoading = false;
        state.editOneSchool.isError = action.payload;
      })
      //getFinancialList
      .addCase(getFinancialList.pending, state => {
        state.getFinancial.isLoading = true;
      })
      .addCase(getFinancialList.fulfilled, (state, action) => {
        state.getFinancial.isLoading = false;
        state.getFinancial.data = action.payload;
        state.getFinancial.isError = false;
      })
      .addCase(getFinancialList.rejected, (state, action) => {
        state.getFinancial.isLoading = false;
        state.getFinancial.isError = action.payload;
      })

      //getFinancialList
      .addCase(getVehiculesList.pending, state => {
        state.getVehicules.isLoading = true;
      })
      .addCase(getVehiculesList.fulfilled, (state, action) => {
        state.getVehicules.isLoading = false;
        state.getVehicules.data = action.payload;
        state.getVehicules.isError = false;
      })
      .addCase(getVehiculesList.rejected, (state, action) => {
        state.getVehicules.isLoading = false;
        state.getVehicules.isError = action.payload;
      })

      //getFinancialList
      .addCase(getSchoolOptionsList.pending, state => {
        state.getSchoolOptions.isLoading = true;
      })
      .addCase(getSchoolOptionsList.fulfilled, (state, action) => {
        state.getSchoolOptions.isLoading = false;
        state.getSchoolOptions.data = action.payload;
        state.getSchoolOptions.isError = false;
      })
      .addCase(getSchoolOptionsList.rejected, (state, action) => {
        state.getSchoolOptions.isLoading = false;
        state.getSchoolOptions.isError = action.payload;
      })

      //getServiceList
      .addCase(getServiceList.pending, state => {
        state.getServices.isLoading = true;
      })
      .addCase(getServiceList.fulfilled, (state, action) => {
        state.getServices.isLoading = false;
        state.getServices.data = action.payload;
        state.getServices.isError = false;
      })
      .addCase(getServiceList.rejected, (state, action) => {
        state.getServices.isLoading = false;
        state.getServices.isError = action.payload;
      })

      //deleteService
      .addCase(deleteServiceDate.pending, state => {
        state.deleteDate.isLoading = true;
      })
      .addCase(deleteServiceDate.fulfilled, (state, action) => {
        state.deleteDate.isLoading = false;
        state.deleteDate.data = action.payload;
        state.deleteDate.isError = false;
      })
      .addCase(deleteServiceDate.rejected, (state, action) => {
        state.deleteDate.isLoading = false;
        state.deleteDate.isError = action.payload;
      })

      //saveService
      .addCase(saveServiceDate.pending, state => {
        state.saveDate.isLoading = true;
      })
      .addCase(saveServiceDate.fulfilled, (state, action) => {
        state.saveDate.isLoading = false;
        state.saveDate.data = action.payload;
        state.saveDate.isError = false;
        state.saveDate.isSuccess = true;
      })
      .addCase(saveServiceDate.rejected, (state, action) => {
        state.saveDate.isLoading = false;
        state.saveDate.isError = action.payload;
      })

      //editService
      .addCase(editServiceDate.pending, state => {
        state.editDate.isLoading = true;
      })
      .addCase(editServiceDate.fulfilled, (state, action) => {
        state.editDate.isLoading = false;
        state.editDate.data = action.payload;
        state.editDate.isError = false;
        state.editDate.isSuccess = true;
      })
      .addCase(editServiceDate.rejected, (state, action) => {
        state.editDate.isLoading = false;
        state.editDate.isError = action.payload;
      })

      .addCase(filterSchool.fulfilled, (state, action) => {
        state.searchSchool = action.payload;
      })

      .addCase(getSchoolsRequests.fulfilled, (state, action) => {
        state.requests = action.payload;
      });
  },
});

export const {
  toggleSchoolDriveDetails,
  filterDrivingSchool,
  emptyStates,
  setServices,
  updateServices,
} = drivingSchoolSlice.actions;

export default drivingSchoolSlice.reducer;
