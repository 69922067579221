/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API_HOST = 'https://api.unidrive.app/';

const CONTENT = {
  data: 'uploads/images',
};
const API = {
  auth: {
    login: 'admin/login',
    refreshToken: '/token/refresh',
  },
  blog: {
    list: 'api/blog/list',
    favorite: '/api/blog/list_blog_favored',
    create: 'api/blog/new',
    delete: 'api/blog',
    edit: 'api/blog/',
    getOneBlog: id => `api/blog/${id}`,
    editImageBLog: 'api/resources/new',
    filter: 'api/blog/filter',
    filterDate: 'api/blog/filter_date',
  },
  spotlightBlog: {
    list: page => `api/featuredArticle/list?page=${page}`,
    featuredArticle: 'front/article_publish',
    create: 'api/featuredArticle/new',
    editImageBLog: 'api/resources/new',
    delete: id => `api/featuredArticle/${id}`,
    edit: id => `api/featuredArticle/${id}/edit`,
  },
  school: {
    create: 'front/sign_up_school',
    get: 'api/school/list',
    delete: id => `api/school/${id}`,
    edit: id => `api/school/${id}/edit`,
    getOneSchool: id => `front/show_school_byidOrSlug/${id}`,
    getFinancialList: id => `front/list_financing/${id}`,
    getVehiculesList: id => `front/list_vehicule/${id}`,
    getSchoolOptions: id => `front/list_options_driving/${id}`,
    approveSchool: id => `api/school/put_isVerified/${id}`,
    filter: 'api/school/list_drivingSchool',
    drivingSchoolRequests: 'api/school/list_drivingSchool_new',
  },
  dateService: {
    addServiceDate: (idDrivingSchool, idService) =>
      `api/date_service/new_date/${idDrivingSchool}/${idService}`,
    getServiceList: id => `front/list_service/${id}`,
    edit: id => `api/date_service/${id}/edit`,
    delete: id => `api/date_service/${id}`,
  },
  permit: {
    create: 'api/permit/new',
    list: 'front/list_type_permis',
  },
  vehiculeType: {
    create: 'api/vehicule/new',
  },
  serviceType: {
    create: 'api/date_service/new',
  },
  financialType: {
    create: 'api/financing/new',
  },
  request: {
    list: 'api/request/list_request_admin',
    filtre: 'api/request/filter',
  },
  adminsList: {
    list: 'api/admin/list',
    add: 'api/admin/new',
    delete: id => `api/admin/${id}`,
    edit: id => `api/admin/${id}`,
  },
  settings: {
    edit: 'api/admin/edit_admin_setting/1',
    adminSettings: id => `api/admin/show_admin_setting/${id}`,
    logo: 'api/admin/show_logo_unidrive',
    tags: {
      list: 'api/tag/list',
      add: 'api/admin/new_category_post',
      edit: id => `api/admin/put_category_post/${id}`,
      delete: id => `api/admin/delete_category_post/${id}`,
    },
  },
  uploadFile: 'api/resources/new',
};

export const replaceApiWithWWW = url => {
  return url.replace('api.', '');
};
export { API_HOST, API, CONTENT };
