import React, { useEffect, useState } from 'react';

import { Card, Row, Col, Button, Modal, Tooltip, notification } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { ArticleCard } from 'container/BlogContainer/blog/style';
import StarButton from './StarButton';
import EditModal from '../../modal/EditModal';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteBLog,
  editBlog,
  filterBlog,
  getFavoriteArticles,
} from 'redux/slices/blog/articles/blogAsyncThunk';
import { isoDateToCustomDate } from 'utils/conversion';
import { API_HOST, replaceApiWithWWW } from 'config/api';

import blogPlaceholderImage from 'assets/image/placeholder-image.jpg';

const ArticleCardItem = ({ title, image, author, id, slug, isFavorite, date }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [blogImage, setBlogImage] = useState('');
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [updatedIsFavorite, setUpdatedIsFavorite] = useState(isFavorite);
  const [updateResponse, setUpdateResponse] = useState({});

  const page = useSelector(state => state.blog.articles?.current_page_number);
  const query = useSelector(state => state.blog.filters.query);
  const favoriteArticles = useSelector(state => state.blog.favoriteArticles?.data);

  const isFavoriteArticlesMaxLength = favoriteArticles?.length === 3;

  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = type => {
    api[type]({
      description: 'Vous ne pouvez choisir que 3 articles préférés.',
    });
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    setIsOpenModalEdit(false);
  };

  const handleOk = () => {
    setIsOpenModal(false);
    dispatch(deleteBLog(id));
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const openModalEdit = () => {
    setIsOpenModalEdit(true);
    setSelectedBlogId(id);
  };

  const handleUpdateIsFavorite = async () => {
    const newIsFavorite = !updatedIsFavorite;
    const isFavoriteState = newIsFavorite ? 1 : 0;

    (!isFavoriteArticlesMaxLength || isFavorite) &&
      dispatch(editBlog({ id, values: { isFavorite: isFavoriteState } }))
        .then(response => {
          setUpdateResponse(response);
          setUpdatedIsFavorite(newIsFavorite);
          dispatch(filterBlog({ page, query }));
          dispatch(getFavoriteArticles());
        })
        .catch(() => openNotificationWithIcon('error'));
  };

  const errorMessage = updateResponse?.error?.message !== 'Rejected';

  useEffect(() => {
    setBlogImage(image?.replace(/^http:\/\//i, 'https://'));
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ArticleCard className="article-card">
      {contextHolder}
      <Row justify="center">
        <Col>
          <Card className="list-card">
            <img
              src={blogImage ? blogImage : blogPlaceholderImage}
              alt="article"
              className="blogCardImage"
            />
            {blogImage ? '' : <p className="placeholder_img_text">PAS D&apos;IMAGE</p>}
            <div className="content_container">
              <h2 className="cardTitle">{title}</h2>
              <p className="article_date">Date de Publication: {isoDateToCustomDate(date)}</p>

              <p>{author}</p>

              <Row justify="space-between" align="middle">
                <Col className="cardButtons">
                  <Button onClick={openModal}>
                    <Tooltip title="Supprimer" showArrow={false}>
                      <FeatherIcon icon="trash-2" size={18} />
                    </Tooltip>
                  </Button>
                  <Button>
                    <Tooltip title="Voir blog" showArrow={false}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${replaceApiWithWWW(API_HOST)}blog/${slug}`}
                      >
                        <FeatherIcon icon="eye" size={18} />
                      </a>
                    </Tooltip>
                  </Button>
                  <Button onClick={openModalEdit}>
                    <Tooltip title="Modifier" showArrow={false}>
                      <FeatherIcon icon="edit" size={18} />
                    </Tooltip>
                  </Button>
                  <StarButton
                    isFavorite={isFavorite}
                    handleUpdateIsFavorite={handleUpdateIsFavorite}
                    errorMessage={errorMessage}
                    isFavoriteArticlesMaxLength={isFavoriteArticlesMaxLength}
                  />
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Supprimer ce blog"
        open={isOpenModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Supprimer"
        cancelText="Annuler"
        okType="danger"
      >
        <p>Voulez-vous vraiment supprimer ce blog ?</p>
      </Modal>
      <EditModal
        page={page}
        isOpenModalEdit={isOpenModalEdit}
        setIsOpenModalEdit={setIsOpenModalEdit}
        setIsOpenModal={setIsOpenModal}
        handleCancel={handleCancel}
        blogId={id}
        image={image}
        selectedBlogId={selectedBlogId}
      />
    </ArticleCard>
  );
};

export default ArticleCardItem;
