import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_HOST, API } from 'config/api';
import axiosService from 'services/axiosService';

export const createSchool = createAsyncThunk('createSchool', async (data, { rejectWithValue }) => {
  const URL = API_HOST + API.school.create;
  try {
    const response = await axiosService.post(`${URL}`, data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getSchoolList = createAsyncThunk(
  'drivingSchool/getSchoolList',
  async ({ data, page }, { fulfillWithValue, rejectWithValue }) => {
    const URL = `${API_HOST + API.school.get}?page=${page}`;

    return axiosService
      .get(URL, data)
      .then(response => fulfillWithValue(response.data))
      .catch(error => rejectWithValue(error.message));
  }
);

export const deleteSchool = createAsyncThunk(
  'drivingSchool/deleteSchool',
  async ({ id, data }, { rejectWithValue }) => {
    const URL = API_HOST + API.school.delete(id);
    try {
      const response = await axiosService.delete(URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editSchool = createAsyncThunk(
  'drivingSchool/editSchool',
  async (data, { rejectWithValue }) => {
    const URL = API_HOST + API.school.edit(data.id);
    try {
      const response = await axiosService.patch(URL, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getOneSchool = createAsyncThunk(
  'drivingSchool/getOnesSchool',
  async ({ id, data }, { rejectWithValue }) => {
    const URL = API_HOST + API.school.getOneSchool(id);
    try {
      const response = await axiosService.get(URL, data);
      const keyModifications = {
        adresse_drinving_school: 'address',
        code_postal: 'codePostal',
        first_name: 'firstName',
        last_name: 'lastName',
        nom_commercial: 'nomCommercial',
        numero_siret: 'numeroSiret',
      };
      const modifiedData = Object.keys(response.data).reduce((acc, key) => {
        const modifiedKey = keyModifications[key] || key;
        acc[modifiedKey] = response.data[key];
        return acc;
      }, {});
      return modifiedData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFinancialList = createAsyncThunk(
  'drivingSchool/getFinancialList',
  async (id, { rejectWithValue }) => {
    const URL = API_HOST + API.school.getFinancialList(id);
    try {
      const response = await axiosService.get(URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getVehiculesList = createAsyncThunk(
  'drivingSchool/getVehiculesList',
  async (id, { rejectWithValue }) => {
    const URL = API_HOST + API.school.getVehiculesList(id);
    try {
      const response = await axiosService.get(URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSchoolOptionsList = createAsyncThunk(
  'drivingSchool/getSchoolOptionsList',
  async (id, { rejectWithValue }) => {
    const URL = API_HOST + API.school.getSchoolOptions(id);
    try {
      const response = await axiosService.get(URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getServiceList = createAsyncThunk(
  'drivingSchool/getServiceList',
  async (id, { rejectWithValue }) => {
    const URL = API_HOST + API.dateService.getServiceList(id);
    try {
      const response = await axiosService.get(URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteServiceDate = createAsyncThunk(
  'drivingSchool/deleteServiceDate',
  async (id, { rejectWithValue }) => {
    const URL = API_HOST + API.dateService.delete(id);
    try {
      const response = await axiosService.delete(URL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveServiceDate = createAsyncThunk(
  'drivingSchool/saveServiceDate',
  async (data, { rejectWithValue }) => {
    const { form, idDrivingSchool, idService } = data;
    const URL = API_HOST + API.dateService.addServiceDate(idDrivingSchool, idService);
    try {
      const response = await axiosService.post(URL, form);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editServiceDate = createAsyncThunk(
  'drivingSchool/editServiceDate',
  async (data, { rejectWithValue }) => {
    const { form, idService } = data;
    const URL = API_HOST + API.dateService.edit(idService);
    try {
      const response = await axiosService.patch(URL, form);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSchoolsRequests = createAsyncThunk(
  'drivingSchool/getSchoolsRequests',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { filter, page } = data;
    const URL = `${API_HOST + API.school.drivingSchoolRequests}?page=${page}`;

    return axiosService
      .post(URL, filter)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error.message));
  }
);

export const approveSchool = createAsyncThunk(
  'drivingSchool/approveSchool',
  async (
    { id, isVerifiedByAdmin, filter, page },
    { fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    const URL = API_HOST + API.school.approveSchool(id);

    return axiosService
      .put(URL, { isVerifiedByAdmin })
      .then(() => fulfillWithValue({ id, isVerifiedByAdmin }))
      .then(() => dispatch(getSchoolsRequests({ filter, page })))
      .catch(error => rejectWithValue(error.message));
  }
);

export const filterSchool = createAsyncThunk(
  'drivingSchool/filterSchool',
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const { filter, page } = data;
    const URL = `${API_HOST + API.school.filter}?page=${page}`;

    return axiosService
      .post(URL, filter)
      .then(res => fulfillWithValue(res.data))
      .catch(error => rejectWithValue(error.message));
  }
);
