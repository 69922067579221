import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BlogPageHeader from './overview/header';
import ArticleList from './overview/list';
import AddArticleModal from './overview/modal';
import { filterBlog, getFavoriteArticles } from 'redux/slices/blog/articles/blogAsyncThunk';

import { BlogContainer } from './style';

const Blog = () => {
  const dispatch = useDispatch();

  const {
    articles,
    filters: { query },
  } = useSelector(state => state.blog);

  const [addArticleModalIsOpen, setaddArticleModalIsOpen] = useState(false);
  const showModalHandler = () => setaddArticleModalIsOpen(true);
  const closeModalHandler = () => setaddArticleModalIsOpen(false);
  const [page, setPage] = useState(1);
  const incrementPage = () => {
    const numItemsPerPage = articles?.num_items_per_page; // Assuming 12 items per page
    const numTotalsItems = articles?.num_totals_items;

    // Calculate the total number of pages based on the total number of items
    const totalPages = Math.ceil(numTotalsItems / numItemsPerPage);

    // Check if there are remaining articles on the next page
    if (page < totalPages) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const decrementPage = () => {
    setPage(prevPage => prevPage - 1);
  };

  useEffect(() => {
    dispatch(getFavoriteArticles());
    dispatch(filterBlog({ page, query }));
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BlogContainer>
      <BlogPageHeader showModalHandler={showModalHandler} page={page} />
      <ArticleList incrementPage={incrementPage} decrementPage={decrementPage} page={page} />
      <AddArticleModal
        page={page}
        open={addArticleModalIsOpen}
        closeModalHandler={closeModalHandler}
      />
    </BlogContainer>
  );
};

export default Blog;
